export const listStudents = /* GraphQL */ `
    query ListStudents(
        $id: ID
        $filter: ModelStudentFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listStudents(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                studentInvoiceId
            }
            nextToken
        }
    }
`;

export const updateStudent = /* GraphQL */ `
    mutation UpdateStudent(
        $input: UpdateStudentInput!
        $condition: ModelStudentConditionInput
    ) {
        updateStudent(input: $input, condition: $condition) {
            id
            studentInvoiceId
        }
    }
`;

export const createInvoice = /* GraphQL */ `
    mutation CreateInvoice(
        $input: CreateInvoiceInput!
        $condition: ModelInvoiceConditionInput
    ) {
        createInvoice(input: $input, condition: $condition) {
            id
            number
            invoiceStudentId
        }
    }
`;

export const updateInvoice = /* GraphQL */ `
    mutation UpdateInvoice(
        $input: UpdateInvoiceInput!
        $condition: ModelInvoiceConditionInput
    ) {
        updateInvoice(input: $input, condition: $condition) {
            id
            number
            invoiceStudentId
        }
    }
`;

export const deleteInvoice = /* GraphQL */ `
    mutation DeleteInvoice(
        $input: DeleteInvoiceInput!
        $condition: ModelInvoiceConditionInput
    ) {
        deleteInvoice(input: $input, condition: $condition) {
            id
            number
            invoiceStudentId
        }
    }
`;

export const listInvoices = /* GraphQL */ `
    query ListInvoices(
        $id: ID
        $filter: ModelInvoiceFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listInvoices(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                invoiceStudentId
                number
                payments {
                    items {
                        id
                    }
                }
                createdAt
            }
            nextToken
        }
    }
`;

export const deletePayment = /* GraphQL */ `
    mutation DeletePayment(
        $input: DeletePaymentInput!
        $condition: ModelPaymentConditionInput
    ) {
        deletePayment(input: $input, condition: $condition) {
            id
        }
    }
`;
