<template>
  <div>
    <b-card>
			<div class="d-flex justify-content-between">
        <div class="mt-1">
          <b-badge variant="primary" class="mr-50">
            Invoices
            <b-spinner v-if="invoices.loading"/>
            <span v-else>
            {{ invoices.items.length }}
          </span>
          </b-badge>
          <b-badge variant="info" class="mr-50">
            With Number
            <b-spinner v-if="invoices.loading"/>
            <span v-else>
            {{ invoices.items.filter(item => item.number !== null ).length }}
            </span>
          </b-badge>
          <b-badge variant="info" class="mr-50">
            Total Payments
            <b-spinner v-if="invoices.loading"/>
            <span v-else>
            {{ invoices.items.reduce((total, invoice) => total + invoice.payments.items.length, 0) }}
          </span>
          </b-badge>
          <b-badge variant="secondary" class="mr-50">
            Students With Invoice ID
            <b-spinner v-if="students.loading" small/>
            <span v-else>
            {{ students.items.filter(item => item.studentInvoiceId !== null ).length }}
          </span>

          </b-badge>
        </div>
        <div>
          <b-button v-if="!loading" :disabled="running" variant="danger" size="sm" class="mr-1" @click="assignInvoiceNumbers">Assign Invoice Numbers</b-button>
          <b-button v-if="!loading" :disabled="running" variant="danger" size="sm" @click="deleteAllInvoices">Delete All Invoices</b-button>
        </div>
      </div>

      <hr>

      <b-overlay :show="loading || running" rounded="sm" opacity="1" variant="white" class="w-100">
        <template #overlay>
          <div class="text-center text-primary my-2">
            <b-icon icon="arrow-clockwise" animation="spin-pulse" font-scale="1"></b-icon> Loading...
          </div>
        </template>
        <b-row>
          <template v-if="!loading && !running">
            <b-col v-for="(invoice, index) in invoices.items" :key="invoice.id" cols="4" class="mb-50 border rounded d-flex">
              <div class="pr-50">{{ index + 1}}</div>
              <div class="d-flex">
                <div>
                  <b-badge variant="primary" class="mr-50">Invoice Id: {{ invoice.id }}</b-badge>
                  <div>
                    <b-badge variant="info" class="mr-50">Invoice Number: {{ invoice.number }}</b-badge>
                    <b-badge variant="info" class="mr-50">Payments: {{ invoice.payments.items.length }}</b-badge>
                  </div>
                </div>
                <div>
                  <b-badge variant="secondary" class="mr-50">Student Id: {{invoice.invoiceStudentId}}</b-badge>
                </div>
              </div>
            </b-col>
          </template>
          <template v-else>
            <b-col></b-col>
          </template>
        </b-row>
      </b-overlay>
    </b-card>

  </div>
</template>

<script>
import {API, graphqlOperation} from 'aws-amplify';
import {
  listStudents,
  updateStudent,
  createInvoice,
  deleteInvoice,
  listInvoices,
  deletePayment,
  updateInvoice
} from './invoices';
import {uuid} from 'vue-uuid';

export default {
  name: 'ToolsInvoice',
  data() {
    return {
      loading: false,
      running: false,
      students: {
        items: [],
        loading: true
      },
      invoices: {
        items: [],
        loading: true
      }
    }
  },
  async mounted() {
    this.loading = true
    await this.listInvoices()
    await this.listStudents()
    this.loading = false
  },
  methods: {
    async listInvoices(nextToken, pagedItems) {
      const items = pagedItems || []
      const input = { limit: 1000, nextToken: nextToken }

      const response = await API.graphql(graphqlOperation(listInvoices, input));
      items.push(...response.data.listInvoices.items)

      if(response.data.listInvoices.nextToken) {
        await this.listInvoices(response.data.listInvoices.nextToken, items)
      }
      else {
        this.invoices.items = items.sort((a, b) => a.createdAt.localeCompare(b.createdAt))
        this.invoices.loading = false
      }
    },
    async listStudents(nextToken, pagedItems) {
      const items = pagedItems || []
      const input = { limit: 1000, nextToken: nextToken }
      const response = await API.graphql(graphqlOperation(listStudents, input));
      items.push(...response.data.listStudents.items)

      if(response.data.listStudents.nextToken) {
        await this.listStudents(response.data.listStudents.nextToken, items)
      }
      else {
        this.students.items = items
        this.students.loading = false
      }
    },

    async deleteAllInvoices() {
      this.running = true
      await this.invoices.items.reduce(async (referencePoint, invoice, index) => {
        try {
          const invoiceInput = { id: invoice.id }
          const studentInput = { id: invoice.invoiceStudentId, studentInvoiceId: null }

          await referencePoint;
          await API.graphql(graphqlOperation(deleteInvoice, { input: invoiceInput } ));
          await API.graphql(graphqlOperation(updateStudent, { input: studentInput } ));

          await invoice.payments.items.reduce(async (innerReference, payment, innerIndex) => {
            try {
              const paymentInput = { id: payment.id }
              await API.graphql(graphqlOperation(deletePayment, { input: paymentInput } ));
            }
            catch (e) { console.error(e) }
          }, Promise.resolve());
        }
        catch (e) { console.error(e) }
      }, Promise.resolve());

      await this.listInvoices()
      await this.listStudents()
      this.running = false
    },

    async assignInvoiceNumbers() {
      this.running = true
      await this.invoices.items.reduce(async (referencePoint, invoice, index) => {
        try {
          await referencePoint;
          await API.graphql(graphqlOperation(updateInvoice, { input: { id: invoice.id, number: index + 1 } } ));
        }
        catch (e) { console.error(e) }
      }, Promise.resolve());

      await this.listInvoices()
      await this.listStudents()
      this.running = false
    },
  }
}
</script>

<style scoped>

</style>
